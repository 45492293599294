import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigurationsService } from '@core/services';
import { UserService } from '@core/services/user.service';
import { AccountManagerService } from 'app/modules/account-manager/account-manager.service';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { HeaderService } from './header.service';
import { NavItem } from './navigation.model';

@Component({
  selector: 'ds365-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  notifications: any;
  newCounts = 0;
  user: any;
  isShowNotifications = false;
  oldUiUrl: string;
  navBarCollapsed = true;
  navItems: NavItem[];
  userMenu: any[];
  isSignupPage = false;
  isAccountManager: boolean;
  userGroups: any[] = [];
  isInRouteAllowGroup = true;
  betaUrl: string;

  constructor(
    public application: AppConfigurationsService,
    private service: HeaderService,
    private router: Router,
    private location: Location,
    private userService: UserService,
    private accountManagerService: AccountManagerService
  ) {
    router.events.subscribe(val => {
      if (this.location.path().indexOf('/auth/signup') > -1) {
        this.isSignupPage = true;
      }
    });
  }

  ngOnInit() {
    this.betaUrl = window.location.protocol + '//' + window.location.hostname.replace('console', 'essentials');
    forkJoin([
      this.userService.getProfile().pipe(
        mergeMap((profile: any) => {
          this.user = profile;
          if (profile && profile.id) {
            return forkJoin([this.accountManagerService.getProfileGroups()]);
          }
          return of([]);
        })
      ),
      this.service.appNavigations('header-nav')
    ]).subscribe(([[groupResult], headerResult]: [[any, any], NavItem[]]) => {
      this.userGroups = groupResult;
      this.navItems = this.handleGroupRoute(headerResult, this.userGroups);
      this.service.getNotifications(this.user.id).subscribe(notifs => {
        this.newCounts = notifs[0].newCounts;
        this.notifications = notifs[0].data;
      })
    });

    // this.userService.getProfile().pipe(
    //   mergeMap((profile:any)=>{
    //     this.user = profile;
    //     if(profile && profile.id){
    //       return this.service.getNotifications(profile.id);
    //     }
    //     return of([]);
    //    })
    // ).subscribe((result)=>{
    //   this.newCounts = result[0].newCounts;
    //   this.notifications = result[0].data;
    // })

    // this.service.appNavigations('header-nav').subscribe(
    //   (configs: NavItem[]) => {
    //     this.navItems = configs;
    //   }
    // )

  }

  switchUi() {
    window.open(this.application.configs.application_url, '_self');
  }
  onNotificationHide(data) {
    this.notifications = data;
    this.newCounts -= 1;
  }
  onDisplayNotifications(isShow) {
    this.service.addUserTimestamp(this.user.id).subscribe(result => {
      this.newCounts = 0;
    });
    this.isShowNotifications = !this.isShowNotifications;
  }

  handleGroupRoute(routes: NavItem[], groups: any[]): NavItem[] {
    routes = routes.filter(route => {
      if (!route.hideFrom) {
        return true
      } else if (route.hideFrom.filter(hideFromGroup => groups.filter(userGroup => userGroup.name.includes(hideFromGroup)).length).length > 0) {
        return false;
      } else {
        return true;
      }
    })
    routes = routes.filter(route => {
      if (!route.groups) {
        return true;
      }
      return route.groups.filter(allowGroup => groups.filter(userGroup => userGroup.name.includes(allowGroup)).length).length;
    });
    return routes;
  }

toNewUI(){
    localStorage.removeItem('switchUI');
    setTimeout(() => {
      window.location.href = this.betaUrl;
    }, 1000)
  }
}
